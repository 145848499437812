import {GPS, Milestone, Position, Trip} from "./data.reducer";

export const getPosition = (): Promise<Position> => {
    return fetch('/current').then(response => response.ok ? response.json() : {Lon: 0, Lat: 0, LastUpdate: new Date(), Since: 0});
}

export const getActiveTrip = (): Promise<Trip | null> => {
    return fetch('/active_trip').then(response => response.ok ? response.json() : null);
}

export const getRoute = (): Promise<GPS[]> => {
    return fetch('/route').then(response => response.ok ? response.json() : [])
}

export const createMilestone = (name: string, description: string): Promise<Milestone> => {
    var formData = new FormData();
    formData.append("name", name)
    formData.append("description", description)

    return fetch('/api/milestones', {method: 'POST', body: formData}).then(response => response.json());
}

export const updateMilestone = (id: number, name: string, description: string, activate: boolean): Promise<Milestone> => {
    var formData = new FormData();
    formData.append("name", name)
    formData.append("description", description)
    formData.append("active", activate ? 'true' : 'false')
    return fetch('/api/milestones/' + id, {method: 'POST', body: formData}).then(response => response.json());
}

export const getMilestones = (): Promise<Milestone[]> => {
    return fetch('/milestones').then(response => response.ok ? response.json() : [])
}

export const deleteMilestone = (id: number): Promise<void> => {
    return fetch('/api/milestones/' + id, {method: 'DELETE'}).then();
}

export const deletePicture = (id: number): Promise<void> => {
    return fetch('/api/picture/' + id, {method: 'DELETE'}).then();
}

export const rotatePicture = (id: number): Promise<void> => {
    return fetch('/api/picture/' + id + "/rotate", {method: 'POST'}).then();
}

export const createAdminTrip = (name: string, description: string): Promise<Trip> => {
    var formData = new FormData();
    formData.append("name", name)
    formData.append("description", description)
    return fetch('/api/start_trip', {method: 'POST', body: formData}).then(response => response.json());
}

export const getAdminTrips = (): Promise<Trip[]> => {
    return fetch('/api/trips').then(response => response.json());
}

export const endTrip = (id: number): Promise<void> => {
    return fetch('/api/stop_trip/' + id, {method: 'POST'}).then();
}

export const getAdminMilestones = (): Promise<Milestone[]> => {
    return fetch('/api/milestones').then(response => response.json());
}