import {useEffect, useState} from "react";
import {Milestone} from "../../../data/data.reducer";
import {
    createMilestone,
    deleteMilestone,
    deletePicture,
    getAdminMilestones,
    rotatePicture,
    updateMilestone,
} from "../../../data/data.api";
import Uploady, {useItemProgressListener} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";

const LogProgress = () => {
    useItemProgressListener((item) => {
        console.log(`>>>>> (hook) File ${item.file.name} completed: ${item.completed}`);
    });

    return null;
}

const AdminMilestones = () => {

    const [state, setState] = useState<{
        selected: Milestone | null,
        milestones: Milestone[],
        name: string,
        description: string,
    }>({
        selected: null,
        milestones: [],
        name: '',
        description: ''
    });

    const loadMilestones = () => {
        getAdminMilestones().then(rep => {
            setState(prevState => ({
                ...prevState,
                milestones: rep,
                selected: (prevState.selected !== null ? rep.find(p => p.ID === prevState.selected?.ID)! : null)
            }))
        })
    }

    const onDeleteMilestone = (id: number) => {
        deleteMilestone(id).then(() => {
            loadMilestones()
        })
    }

    useEffect(() => {
        loadMilestones()
    }, [])

    const submitForm = (event: any) => {
        if (state.selected) {
            updateMilestone(state.selected.ID, state.name, state.description, state.selected.Active).then(() => loadMilestones())
        } else {
            createMilestone(state.name, state.description).then(() => loadMilestones())
        }
        event.preventDefault();
    };
    const handleChange = (event: any) => {
        setState(prevState => ({...prevState, [event.target.name]: event.target.value}))
    };

    const onActivate = (activate: boolean) => {
        if (state.selected === null) {
            return
        }
        updateMilestone(state.selected.ID, state.selected.Name, state.selected.Description, activate).then(() => loadMilestones())
    };

    const onDeletePicture = (id: number) => {
        deletePicture(id).then(() => loadMilestones())
    };

    const onRotate = (id: number) => {
        rotatePicture(id).then(() => loadMilestones())
    };

    return (<div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">Milestones</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                        {state.selected === null ? "Milestone anlegen" : 'Update Milestone'}
                    </h6>
                </div>
                <div className="card-body">
                    <form id="newMilestoneForm" className="form-inline" encType="multipart/form-data"
                          onSubmit={submitForm}>
                        <div className="form-group mb-2">
                            <label htmlFor="name">Name:</label>
                            <input type="text" className="form-control" id="name" name="name"
                                   onChange={handleChange}
                                   value={state.name}
                                   placeholder="Milestone Name"/>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="description">Description:</label>
                            <input type="text" className="form-control" id="description" name="description"
                                   onChange={handleChange}
                                   value={state.description}
                                   placeholder="Milestone Description"/>
                        </div>
                        {state.selected && <div className="form-group mb-2">
                            <label htmlFor="pictures">Pictures:</label>
                            {state.selected.Pics && state.selected.Pics.map(p => <div style={{width: 200, height: 200}}>
                                <img width={100} height={100} src={`/picture/${p.ID}`}/>
                                <button onClick={() => onDeletePicture(p.ID)}>Remove</button>
                                <button onClick={() => onRotate(p.ID)}>Rotate</button>
                            </div>)}
                            <Uploady
                                destination={{url: `/api/milestones/${state.selected.ID}/upload`}}>
                                <LogProgress/>
                                <UploadButton/>
                            </Uploady>
                        </div>}
                        <button id="submit" type="submit"
                                className="btn btn-primary mb-2">{state.selected === null ? "Milestone anlegen" : 'Update Milestone'}</button>
                        {state.selected && <button className="btn btn-primary mb-2"
                                                   onClick={() => setState(prevState => ({
                                                       ...prevState,
                                                       selected: null,
                                                       name: '',
                                                       description: ''
                                                   }))}>Deselect</button>}
                        {state.selected && state.selected.Active && <button onClick={(event) => {
                            onActivate(false);
                            event.preventDefault();
                        }}>❌ Deactivate</button>}
                        {state.selected && !state.selected!.Active && <button onClick={(event) => {
                            onActivate(true);
                            event.preventDefault();
                        }}>✅ Activate</button>}
                    </form>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Milestones</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Active</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Fotos/Videos</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody id="tbody">
                            {state.milestones.map(t => (
                                <tr key={`trip_${t.ID}`}>
                                    <td>{t.ID}</td>
                                    <td>{t.Active ? '✅' : '❌'}</td>
                                    <td>{t.Name}</td>
                                    <td>{t.Description}</td>
                                    <td>{t.Pics.length}</td>
                                    <td>
                                        <button onClick={() => onDeleteMilestone(t.ID)}>Remove</button>
                                        <button
                                            onClick={() => setState(prevState => ({
                                                ...prevState,
                                                selected: t,
                                                description: t.Description,
                                                name: t.Name
                                            }))}>Select
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default AdminMilestones;