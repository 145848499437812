import React, {useEffect, useReducer} from 'react';
import './App.css';
import IntoSection from "./sections/IntoSection";
import SocialSection from "./sections/SocialSection";
import RouteSection from './sections/RouteSection';
import {DataInitialState, mainReducer} from "../../data/data.reducer";
import {DataContext} from "../../data/data.context";
import {DataProvider} from "../../data/data.provider";
import MilestonesSection from "./sections/MilestonesSection";
import HomeSection from './sections/HomeSection';

const App = () => {
    const [state, dispatch] = useReducer(mainReducer, DataInitialState);

    const createScriptTag = (src: string) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        return script;
    };

    // @ts-ignore
    return (
        <DataContext.Provider value={{state, dispatch}}>
            <DataProvider>
                <IntoSection/>
                <HomeSection/>
                <MilestonesSection/>
                <RouteSection/>
                <SocialSection/>
                <div className="copyright">&copy; Fabian Suhrau. All rights reserved. Design: <a
                    href="https://html5up.net">HTML5 UP</a>.
                </div>
            </DataProvider>
        </DataContext.Provider>
    );
}

export default App;
