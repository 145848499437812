import React from "react";
import {DataContext} from "./data.context";
import {getActiveTrip, getMilestones, getPosition, getRoute} from "./data.api";
import {InitialActions, Milestone, Position, Route, Trip} from "./data.reducer";

export const usePosition = (): Position => {
    const {state, dispatch} = React.useContext(DataContext)
    return state.position;
}

export const useActiveTrip = (): Trip | null => {
    const {state, dispatch} = React.useContext(DataContext)
    return state.active_trip;
}

export const useMilestones = (): Milestone[] => {
    const {state, dispatch} = React.useContext(DataContext)
    return state.milestones;
}
export const useRoute = (): Route => {
    const {state, dispatch} = React.useContext(DataContext)
    return state.route;
}
export const DataProvider = (props: any) => {

    const {state, dispatch} = React.useContext(DataContext);

    const [uiState, setUiState] = React.useState<{ loading: boolean, message: string }>({
        loading: true,
        message: 'Lade'
    });

    React.useEffect(() => {
        Promise.all([
            getPosition(),
            getActiveTrip(),
            getMilestones(),
            getRoute(),
        ]).then(result => {
            if (dispatch != null) {
                dispatch({
                    type: InitialActions.Initial, payload: {
                        position: result[0],
                        active_trip: result[1],
                        milestones: result[2],
                        gps: result[3],
                    }
                })
            }
        }).then(() => {
            setUiState(prevState => ({...prevState, message: '', loading: false}))
        })
    }, [dispatch])

    return (
        <>
            {!uiState.loading && props.children}
            {uiState.loading && <div>
                <h5>{uiState.message}</h5>
            </div>}
        </>
    )
};