import {tripReducer} from "./tripReducer";
import {routeReducer} from "./route.reducer";
import {milestoneReducer} from "./milestone.reducer";
import {LatLng} from "leaflet";
import {positionReducer} from "./positionReducer";

export interface Position {
    Lat: number
    Lon: number
    Position: LatLng
    Since: number
    LastUpdate: Date
}

export interface Trip {
    ID: number
    Name: string
    Description: string
    IsActive: boolean
}

export interface GPS {
    Lat: number
    Lon: number
}

export interface Picture {
    ID: number
    Orientation: 'landscape' | 'portrait'
}

export interface Milestone {
    ID: number
    Lat: number
    Lon: number
    Name: string
    Description: string
    Active: boolean
    Pics: Picture[]
    CreatedAt: Date
}

export interface Route {
    distance: number,
    positions: LatLng[],
}

export enum InitialActions {
    Initial = "initial",
}

export interface DataState {
    position: Position
    active_trip: Trip|null
    milestones: Milestone[]
    route: Route
}

export const DataInitialState: DataState = {
    position: { Lon: 0, Lat: 0, Position: new LatLng(0 ,0), LastUpdate: new Date(), Since: 0},
    active_trip: null,
    milestones: [],
    route: {distance: 0, positions: []},
}

export const mainReducer = (state: DataState, action: any) => {
    return {
        position: positionReducer(state, action),
        active_trip: tripReducer(state, action),
        milestones: milestoneReducer(state, action),
        route: routeReducer(state, action),
    }
}
