import {useMilestones} from "../../../data/data.provider";
import {formatPosition} from "./RouteSection";
import {LatLng} from "leaflet";
import Gallery from "./Gallery";

const MilestonesSection = () => {
    const checkpoints = useMilestones();
    const width = 820;
    const height = 615;
    return (
        <>
            {checkpoints.map((milestone, index) => (
                <section key={`section_${index}`} id={index === 0 ? 'first' : `section_${index}`}>
                    <header>
                        <h2>{milestone.Name}</h2>
                        <p><a target="_blank"
                              href={`https://www.google.de/maps/@${milestone.Lat},${milestone.Lon},15z?entry=ttu`}>{formatPosition(new LatLng(milestone.Lat, milestone.Lon))}</a>
                        </p>
                        <p>{new Date(milestone.CreatedAt).toLocaleString()}</p>
                    </header>
                    <div className="content">
                        <p><strong>{milestone.Name}:</strong> {milestone.Description}</p>
                        <Gallery pics={milestone.Pics}/>
                    </div>
                </section>
            ))}
        </>
    )
}
export default MilestonesSection;