import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/app/App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Admin from "./routes/admin/Admin";
import AdminTrips from "./routes/admin/pages/AdminTrips";
import AdminMilestones from "./routes/admin/pages/AdminMilestones";

const router = createBrowserRouter([
    {
        path: "/",
        element: <>
            <App/>
        </>,
    },
    {
        path: "/admin",
        element: <Admin/>,
        children: [
            {
                path: "trips",
                element: <AdminTrips/>
            },
            {
                path: "milestones",
                element: <AdminMilestones/>
            }
        ]
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
