import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import React from "react";
import 'moment-timezone';
import {useActiveTrip, usePosition, useRoute} from "../../../data/data.provider";
import {formatPosition} from "./RouteSection";

const IntroSection = () => {

    const formatSince = (seconds: number): string => {

        // Convert pos.Since to a human-readable format
        var days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        var hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds -= Math.floor(mnts * 60);

        var sinceText = '';
        if (days > 0) {
            sinceText += days + " days, ";
        }
        if (hrs > 0) {
            sinceText += hrs + " Hrs, ";
        }
        if (mnts > 0) {
            sinceText += mnts + " Minutes, ";
        }
        if (seconds > 0) {
            sinceText += Math.floor(seconds) + " Seconds";
        }
        return sinceText
    }

    const route = useRoute();
    const active_trip = useActiveTrip();
    const position = usePosition();

    return (
        <section className="intro">
            <header>
                <h1 id="active-trip-title">{active_trip ? active_trip.Name : 'TripTracker'}</h1>
                <p>{active_trip ? active_trip.Description : ''}</p>
                <p>Aktuelle Position: <span id="position-current">
                    <a target="_blank"
                       href={`https://www.google.de/maps/@${position.Position.lat},${position.Position.lng},15z?entry=ttu`}>{formatPosition(position.Position)}
                    </a>
                    </span></p>
                <p>Seit: <span id="position-since">{formatSince(position.Since)}</span></p>
                <p>Zuletzt aktualisiert: <span id="position-update">{new Date(position.LastUpdate).toLocaleString()}</span></p>
                <p>Zurückgelegte Strecke: <span id="distance">{route.distance.toFixed(2)} Km</span></p>
                <ul className="actions">
                    <li><a href="#first" className="arrow scrolly"><span className="label">Next</span></a></li>
                </ul>
            </header>
            <MapContainer className={"content"}
                          center={position.Position}
                          zoom={17}
                          dragging={false}
                          touchZoom={false}
                          doubleClickZoom={false}
                          scrollWheelZoom={false}
                          boxZoom={false}
                          keyboard={false}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position.Position}>
                    <Popup>
                        Aktuelle Position
                    </Popup>
                </Marker>
            </MapContainer>
        </section>
    )
}

export default IntroSection;