import {DataState, InitialActions} from "./data.reducer";
import {LatLng} from "leaflet";

export enum PositionActions {
    Update = 'position/Update',
}

export const positionReducer = (state: DataState, action: any) => {
    switch (action.type) {
        case InitialActions.Initial: {
            return {
                ...action.payload.position,
                Position: new LatLng(action.payload.position.Lat, action.payload.position.Lon)
            }
        }
        case PositionActions.Update: {
            return {
                ...action.payload.position,
                Position: new LatLng(action.payload.position.Lat, action.payload.position.Lon)
            }
        }
        default:
            return state.position
    }
}
