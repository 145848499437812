import {DataState, GPS, InitialActions} from "./data.reducer";
import {calculateDistance} from "../routes/app/sections/RouteSection";

export enum RouteActions {
    Update = 'route/Update',
}

export const routeReducer = (state: DataState, action: any) =>  {
    switch (action.type) {
        case InitialActions.Initial: {
            if (action.payload.gps === null) {
                return {positions: [], distance: 0}
            }
            var pos = action.payload.gps.map((r: GPS) => [r.Lat, r.Lon])
            var distance = 0;
            for (var i = 0; i < pos.length - 1; i++) {
                distance += calculateDistance(pos[i][0], pos[i][1], pos[i+1][0], pos[i+1][1]);
            }
            return {positions: pos, distance: distance}
        }
        case RouteActions.Update: {
            if (action.payload.gps === null) {
                return {positions: [], distance: 0}
            }
            var pos = action.payload.gps.map((r: GPS) => [r.Lat, r.Lon])
            var distance = 0;
            for (var i = 0; i < pos.length - 1; i++) {
                distance += calculateDistance(pos[i][0], pos[i][1], pos[i+1][0], pos[i+1][1]);
            }
            return {positions: pos, distance: distance}
        }
        default:
            return state.route
    }
}
