import {DataState, InitialActions} from "./data.reducer";

export enum MilestoneActions {
    Update = 'milestone/Update',
}

export const milestoneReducer = (state: DataState, action: any) =>  {
    switch (action.type) {
        case InitialActions.Initial: {
            if (action.payload.milestones === null) {
                return []
            }
            return action.payload.milestones
        }
        case MilestoneActions.Update: {
            if (action.payload.milestones === null) {
                return []
            }
            return action.payload.milestones
        }
        default:
            return state.milestones
    }
}
