import {useActiveTrip, useMilestones, usePosition, useRoute} from "../../../data/data.provider";
import React from "react";
import {MapContainer, Marker, Polyline, Popup, TileLayer} from 'react-leaflet'
import {LatLng, LatLngBounds} from "leaflet";

export const formatPosition = (pos: LatLng): string => {
    return `${pos.lat.toFixed(2)}°N, ${pos.lng.toFixed(2)}°E`
};

export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg: number): number {
    return deg * (Math.PI / 180)
}

const getBounds = (positions: LatLng[]) => {
    if (positions.length <= 0) {
        return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
    }
    const bounds = new LatLngBounds(positions[0], positions[0]);

    positions.forEach(position => {
        bounds.extend(position);
    });

    return bounds;
};

const RouteSection = () => {
    var trip = useActiveTrip();
    var route = useRoute();
    var current = usePosition();
    var milestones = useMilestones();

    var bounds = getBounds(route.positions);

    return (<>
            {trip === null ? null : <section id="route-container">
                <header>
                    <h2>Route</h2>
                    <p>Zurückgelegte Strecke: <span id="distance">{route.distance.toFixed(2)} Km</span></p>
                </header>
                <MapContainer className="content" bounds={bounds}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polyline pathOptions={{color: 'red'}} positions={route.positions}/>
                    <Marker position={current.Position}>
                        <Popup>
                            Aktuelle Position
                        </Popup>
                    </Marker>
                    {milestones.map(milestone => (
                        <Marker key={`milestone_${milestone.ID}`} position={[milestone.Lat, milestone.Lon]}>
                            <Popup>
                                {milestone.Name}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </section>
            }</>
    )
}

export default RouteSection;