import {DataState, InitialActions} from "./data.reducer";

export enum TripActions {
    Update = 'trip/Update',
}

export const tripReducer = (state: DataState, action: any) =>  {
    switch (action.type) {
        case InitialActions.Initial: {
            return action.payload.active_trip
        }
        case TripActions.Update: {
            return action.payload.active_trip
        }
        default:
            return state.active_trip
    }
}
