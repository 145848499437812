import {useEffect, useState} from "react";
import {Trip} from "../../../data/data.reducer";
import {createAdminTrip, endTrip, getAdminTrips} from "../../../data/data.api";

const AdminTrips = () => {

    const [state, setState] = useState<{
        active_trip: Trip | undefined,
        trips: Trip[],
        name: string,
        description: string,
    }>({
        active_trip: undefined,
        trips: [],
        name: '',
        description: ''
    });

    const loadTrips = () => {
        getAdminTrips().then(rep => {
            setState(prevState => ({...prevState, trips: rep, active_trip: rep.find(trip => trip.IsActive)}))
        })
    }

    const onEndTrip = (id: number) => {
        endTrip(id).then(() => {
            loadTrips()
        })
    }

    useEffect(() => {
        loadTrips()
    }, [])

    const submitForm = (event: any) => {
        createAdminTrip(state.name, state.description).then(() => loadTrips())
        event.preventDefault();
    };
    return (<div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">Trips</h1>

            {state.active_trip === undefined && <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Einen neuen Trip Starten</h6>
                </div>
                <div className="card-body">
                    <form id="newTripForm" className="form-inline" encType="multipart/form-data" onSubmit={submitForm}>
                        <div className="form-group mb-2">
                            <label htmlFor="name">Name:</label>
                            <input type="text" className="form-control" id="name" name="name"
                                   placeholder="Name"
                                   value={state.name} onChange={(event) => setState(prevState => ({
                                ...prevState,
                                name: event.target.value
                            }))}/>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="description">Beschreibung:</label>
                            <input type="text" className="form-control" id="description" name="description"
                                   placeholder="Description"
                                   value={state.description}
                                   onChange={(event) => setState(prevState => ({
                                       ...prevState,
                                       description: event.target.value
                                   }))}
                            />
                        </div>
                        <button type={'submit'} className="btn btn-primary mb-2">Start Trip</button>
                    </form>
                </div>
            </div>}

            {state.active_trip && <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">{state.active_trip.Name}</h6>
                </div>
                <div className="card-body">
                    <p>{state.active_trip.Description}</p>
                    <button className="btn btn-danger stopTripButton"
                            onClick={() => onEndTrip(state.active_trip!.ID)}>Stop Trip
                    </button>
                </div>
            </div>}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Vergangene Trips</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                            </tr>
                            </thead>
                            <tbody id="tbody">
                            {state.trips.map(t => (
                                <tr key={`trip_${t.ID}`}>
                                    <td>{t.ID}</td>
                                    <td>{t.Name}</td>
                                    <td>{t.Description}</td>
                                    <td>{t.IsActive ? 'yes' : 'no'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default AdminTrips;