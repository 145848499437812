import React, {useReducer} from 'react';
import './Admin.css'
import {DataInitialState, mainReducer} from "../../data/data.reducer";
import {DataContext} from "../../data/data.context";
import {DataProvider} from "../../data/data.provider";
import {Outlet, useLocation, useRoutes} from "react-router-dom";

const Admin = () => {
    const [state, dispatch] = useReducer(mainReducer, DataInitialState);

    const location = useLocation();
    const { hash, pathname, search } = location;

    const isActive = (path: string): string =>  {
        return pathname === path ? ' active' : ''
    }

    // @ts-ignore
    return (
        <DataContext.Provider value={{state, dispatch}}>
            <DataProvider>
                <div id={"admin-wrapper"}>
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <i className="fas fa-laugh-wink"></i>
                        </div>
                        <div className="sidebar-brand-text mx-3">Trip Tracker Admin</div>
                    </a>
                    <hr className="sidebar-divider my-0"/>

                    <li className={"nav-item" + isActive("/admin")}>
                        <a className="nav-link" href="/admin">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    <hr className="sidebar-divider"/>

                    <li className={"nav-item" + isActive("/admin/trips")}>
                        <a className="nav-link" href="/admin/trips">
                            <i className="fas fa-fw fa-chart-area"></i>
                            <span>Trips</span></a>
                    </li>

                    <li className={"nav-item" + isActive("/admin/milestones")}>
                        <a className="nav-link" href="/admin/milestones">
                            <i className="fas fa-fw fa-table"></i>
                            <span>Milestones</span></a>
                    </li>

                </ul>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Outlet/>
                    </div>
                </div>
                </div>
            </DataProvider>
        </DataContext.Provider>
    );
}

export default Admin;
