import React from "react";
import {Picture} from "../../../data/data.reducer";
import 'react-photo-view/dist/react-photo-view.css';
import {PhotoProvider, PhotoView} from "react-photo-view";

export interface GalleryProps {
    pics: Picture[]
}

const Gallery = (props: GalleryProps) => {
    const {pics} = props;

    if (pics.length === 1) {
        return (<span className="image main">
            <img src={`/picture/${pics[0].ID}?scaled=true`} alt=""/>
        </span>)
    }

    return (
        <div className="gallery">
            <PhotoProvider>
                {pics.map(p => (
                    <a key={`image_${p.ID}`} className={p.Orientation}>
                        <PhotoView src={`/picture/${p.ID}`}>
                            <img src={`/picture/${p.ID}`} alt=""/>
                        </PhotoView>
                    </a>))}
            </PhotoProvider>
        </div>)
}

export default Gallery;