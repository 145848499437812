import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

const SocialSection = () => {
    return (
        <section>
            <header>
                <h2>Folge mir</h2>
            </header>
            <div className="content">
            </div>
            <footer>
                <ul className="items">
                    <li>
                        <h3>Netzwerke</h3>
                        <ul className="icons">
                            {
                                /*
                                <li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
                                <li><a href="#" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a>
                                </li>
                                 */
                            }

                            <li><a target="_blank" href="https://www.instagram.com/dofi86/" className="icon">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            </li>
                            {
                                /*
                                <li><a href="#" class="icon brands fa-linkedin-in"><span class="label">LinkedIn</span></a>
                                </li>
                                <li><a href="#" class="icon brands fa-github"><span class="label">GitHub</span></a></li>
                                <li><a href="#" class="icon brands fa-codepen"><span class="label">Codepen</span></a></li>
                                 */
                            }
                        </ul>
                    </li>
                </ul>
            </footer>
        </section>
    )
};

export default SocialSection;
