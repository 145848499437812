import {useActiveTrip, useMilestones} from "../../../data/data.provider";
import Gallery from "./Gallery";
import {formatPosition} from "./RouteSection";
import {LatLng} from "leaflet";

const HomeSection = () => {
    const active_trip = useActiveTrip();
    return (
        <>
            {active_trip ? null : <section id={'first'}>
                <header>
                    <h2>Zu Hause!</h2>
                </header>
                <div className="content">
                    <p><strong>Zu Hause:</strong> Aktuell bin ich nicht unterwegs. Finde aktuelles in meinen Socials.</p>
                </div>
            </section>}
        </>
    )
}
export default HomeSection;